import logo from './logo.svg';
import './App.css';
import './bootstrap.css'
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Navigation from "./Router"

function App() {
  return (
    <BrowserRouter>
      <Navigation />
    </BrowserRouter>
  );
}

export default App;
