import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import "../bootstrap.css";
import {logo,list,bot  } from "../theme/Icons";


const menuItems = [
  { item: "Find trials", url: "/" },
  { item: "Sponsors", url: "https://patientrials.com/" },
  { item: "About", url: "https://patientrials.com/" },
  // { item: "Blog", url: "/blog" },
];

const signUp = () => {
  localStorage.setItem("currentPage", window.location.href);
  window.location.href = "#";
};

const Header = (props) => {
  const { showHeader, logout, loggedin } = props;
  const location = useLocation();
  const itemClick = (name) => {
    setClickedItem(name);
  };
  const [clickedMenuItem, setClickedItem] = useState("");
  const MenuItem = (i, url, itemName, activeState) => {
    return (
      <li
        key={i}
        onClick={() => itemClick(itemName)}
        className={
          itemName === clickedMenuItem ? "nav-item active" : activeState
        }
      >
        <a href={url} className="nav-link">
          {itemName}
        </a>
      </li>
    );
  };

  return (
    <div style={{ display: `${showHeader === false && "none"}` }}>
      <div className="menubar container-fluid px-0 py-0">
        <nav className="navbar navbar-expand-lg px-2 mx-2 py-0">
          <a href="/">
              <img style={{height:60}} className="mx-2" src={bot} alt="bot"></img>
              <img
                className="navbar-brand logo"
                alt="logo"
                src={logo}
              ></img>

          </a>
          <button
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#menu"
          >
            <span className="navbar-toggler-icon">
              <img src={list} alt="header menu icon"></img>
            </span>
          </button>
          <div id="menu" className="collapse navbar-collapse d-flex align-items-center justify-content-between">
            {/* {environment === "DEV" ? (
              <p className="mb-0 mx-2" style={{ color: "darkorange" }}>
                DEV
              </p>
            ) : null} */}
            <div className="col-lg-8">
                <div className="d-flex align-items-center justify-content-center">
                    <h5 className="my-3">Trials.me Copilot for Clinical Trials</h5>
                </div>
            </div>
            <ul className="navbar-nav">
              {menuItems.map((menuitem, i) =>
                MenuItem(i, menuitem.url, menuitem.item, "nav-item")
              )}
            </ul>
            {loggedin === true ? 
            
          <button
          onClick={() => logout()}
          rel="noopener noreferrer"
          className="signin btn btn-lg ml-2"
          target="_blank"
        >
          Sign Out
        </button>
          :
          <button
            onClick={() => signUp()}
            rel="noopener noreferrer"
            className="signin btn btn-lg ml-2"
            target="_blank"
          >
            SIGN UP
          </button>
          }
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
