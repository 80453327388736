import React, { useEffect, useState, useRef } from "react";
import { bot, cancer_icon, cardio_icon, diabetes_icon, left_icon, nephrology_icon, parkinson_icon, receiving_icon, right_icon, send_message_icon } from "../theme/Icons";
//import { fetchChat, inputMessage } from "../Api";
import PTCard from "../components/PTCard";
import Header from "../components/Header";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchChatActivity, fetchConditionGroups, fetchNlp, fetchUser, saveChatActivity, updateChatActivity, userLogout } from "../Api";
import { get_date_time, get_specific_date_time, prod_studies } from "../helpers/Helper";
import ReactMarkdown from 'react-markdown';

const socket = new WebSocket('wss://beta.etrials.io/wss')
//const socket = new WebSocket('ws://localhost:3002/ws')

// const carousel_items = [
//   {
//     text : "Cancer",
//     icon : cancer_icon,
//     id : 0,
//   }, 
// ]

const carousel_items = {
  "names": [
    "Cancer",
    "Nephrology",
    "Cardio Vascular",
    "Parkinsons",
    "Diabetes"
],
"images": [
  cancer_icon,
  nephrology_icon, 
  cardio_icon,
  parkinson_icon,
  diabetes_icon
]
}

const dia_img = "https://www.niddk.nih.gov/-/media/Images/Health-Information/Diabetes/BloodGlucoseImage2Feb231200x800.jpg";

const HomeController = () => {

  const [prev_activity,set_prev_activity] = useState([])
  const [total_chat, set_total_chat] = useState([])
  const [user, set_user] = useState("")
  const [selected_card, set_selected_card] = useState(carousel_items[2])
  const [cardIndex, setCardIndex] = useState(1)
  const [cards, set_cards] = useState(carousel_items)
  const [groups, set_groups] = useState(carousel_items)
  const [selected_chat, set_selected_chat] = useState("")
  // const [messages,set_Messages] = useState([])
  // const [watermark,set_Watermark] = useState([])
  const [input_message,set_Input_Message] = useState([])
  const [receiving,set_receiving] = useState(false)
  const [rightIndex, setRightIndex] = useState(0)
  const [leftIndex, setLeftIndex] = useState(0)

  const chatRef = useRef(null);
  const HScroller = useRef(null);
  const uid = useParams("uid")

  // constructor(props) {
  //   super();
  //   this.state = {
  //     all_messages:[],
  //     messages : [],
  //     watermark:"",
  //     input_message : "",
  //   };
  // }

  // const markdown = `
  // # Heading 1
  // ## Heading 2
  // **Bold text**
  // *Italic text*
  // - List item 1
  // - List item 2
  // \`Inline code\`
  // `;

  const handleTextChange = (e) =>{
    set_Input_Message(e.target.value)
  }

  const handleKeyDown = (e) =>{
    if(e.key === "Enter"){
    sendMessage(input_message)
    }
  }


  const left = () => {
    // Create copies of the current state to avoid direct mutation
    let newNames = [...groups.names];
    let newImages = [...groups.images];
    // Perform rotation logic: move the last element to the beginning
    newNames.unshift(newNames.pop());
    newImages.unshift(newImages.pop());
    // Update the state with the rotated items
    set_groups({
      names: newNames,
      images: newImages
    });
    // Update the index to cycle through
    setCardIndex(cardIndex <= 0 ? newNames.length - 1 : cardIndex - 1);
  };
  

  const getCi = (index) =>{
    if(index > 4){
      return 0
    }else if(index < 0){
      return 4
    }else{
      return index
    }
  }

  const right = () => {
    // Create copies of the current state to avoid mutating original data
    let newNames = [...groups.names];
    let newImages = [...groups.images];
    // Perform rotation logic: move first element to the end
    newNames.push(newNames.shift());
    newImages.push(newImages.shift());
    // Update the state with the rotated items
    set_groups({
      names: newNames,
      images: newImages
    });
    // Update the index to cycle through
    setCardIndex(cardIndex >= newNames.length - 1 ? 0 : cardIndex + 1);
  };
  

//   const left = () => {
//     console.log("left clicked")
//    let cItems = [...cards]
//    let rIndex = cardIndex <= 0 ? 4 : cardIndex - 1;
//    let item_to_add = cItems.pop()
//    cItems.unshift(item_to_add)
//    setCardIndex(rIndex)
//    //scrollToCard(rIndex)
//    console.log(cItems,rIndex,'c ri')
//    set_cards(cItems)
//  };
  // sendMessage = ()=>{
  //   inputMessage(this.state.input_message).then(sent_text=>{
  //       console.log(sent_text,'messages found');
  //       fetchChat(this.state.watermark).then(got_chat=>{
  //         console.log(got_chat,'got chat');
  //         let all_messages = this.state.all_messages;
  //         let include_latest = all_messages.concat(got_chat.activities)
  //         this.setState({ all_messages : include_latest},()=>{
  //           this.setState({messages : include_latest},()=>{
  //             this.setState({watermark: got_chat.watermark})
  //           })
  //         })
  //       }).catch(err_chat=>{
  //         console.log(err_chat,'err with chat')
  //         this.setState({messages : []})
  //       })
  //       //this.setState({ messages : sent_text})
  //   }).catch(errText=>{
  //       console.log(errText,'err while fetching messages');
  //       this.setState({ messages : []})
  //   })
  //   this.setState({input_message : ""})
  // }

const check_ws_state = (status)=>{
    switch (status) {
        case socket.CONNECTING:
            console.log('...WebSocket is connecting...');
            break;
        case socket.OPEN:
            console.log('...WebSocket is open.');
            break;
        case socket.CLOSING:
            console.log('...WebSocket is closing...');
            break;
        case socket.CLOSED:
            console.log('...WebSocket is closed.');
            break;
        default:
            console.log('...Unknown WebSocket state:', socket.readyState);
            break;
    }
}

 const sendMessage = (message) =>{
  console.log(socket.readyState, socket.OPEN, 'socket state')
  if( (socket.readyState != socket.OPEN) ){
        socket.onopen = (event)=>{
          console.log(event,'reopening socket');
          socket.send(JSON.stringify({message:message, uid:uid}))
          //socket.send(JSON.stringify({type: "not_message"}))
          set_total_chat((prevMessages) => [...prevMessages,...[{ text :  message, role: "user", id : uid, received_at : get_date_time()}]]);
          set_Input_Message("")
          set_receiving(true)
        }
      }else{
    socket.send(JSON.stringify({message:message, uid:uid}))
    // if(total_chat && total_chat.length > 0){
    //   let user_message = [{ text : message, role : "user", id : uid.uid }]
    //   set_total_chat([...total_chat, ...user_message])
    // }else{
    //   let user_message = [{ text : message, role : "user", id : uid.uid }]
    //   set_total_chat(user_message)
    // }
    set_total_chat((prevMessages) => [...prevMessages,...[{ text :  message, role: "user", id : uid, received_at : get_date_time()}]]);
    set_Input_Message("")
    set_receiving(true)

    fetchConditionGroups(message).then(gotCondition=>{
      let c_result = gotCondition && gotCondition.result ;
      console.log(gotCondition,"gotCondition")
      console.log(c_result, 'got c result')
      if(c_result !== undefined){
        let c_names = c_result.names
        let c_imgs = c_result.images
        let c_arr = []
        if((c_names && c_names.length > 3) && (c_imgs && c_imgs.length > 3)){ 
          // c_names.map((item,i)=>{
          //   let trial = {
          //     text : item.text,
          //     icon : c_imgs[i]
          //   }
          //   return c_arr.push(trial)
          // })
          // setTimeout(()=>{
          //   console.log(c_arr,'c items')
          //   set_groups(c_arr)
          // }, 900)

          set_groups(c_result);

        }
      }else{
        set_groups([])
      }
    }).catch(errCondition=>{
      console.log(errCondition,'err c groups')
      set_groups([])
    })
    // fetchNlp(message).then(output=>{
    //   console.log(output, "got nlp output")
      
    // }).catch(errOutput=>{
    //   console.log(errOutput,'err fetching nlp')
    // })
      }
  }

  const cardRefs = useRef([]);

  useEffect(() => {
    cardRefs.current = cardRefs.current.slice(0, cards.length);
  }, [cards]);

  const scrollToCard = (index) => {
    const card = cardRefs.current[index];
    if (card) {
      card.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };

  // useEffect(() => {
  //   // let c_items = cards;
  //   // let center_card_index = Math.round(cards.length / 2);
  //   // scrollToCard(center_card_index - 2);
  //   scrollToCard(cardIndex)
  // }, [cardIndex]);

  const getCardRight = (id) =>{
    if(id === 1){
      return 0
    }else if(id === 2){
      return 160
    }else if(id === 3){
      return 335
    }else if(id === 4){
      return 500
    } else if(id === 5){
      return 660
    }
  }

  const getZindex = (id) =>{
    if(id === 1){
      return 0
    }else if(id === 2){
      return 10
    }else if(id === 3){
      return 20
    }else if(id === 4){
      return 10
    } else if(id === 5){
      return 0
    }
  }

  const ChatItems = (props) =>{
    let {initiated} = props
    return (
      <div ref={HScroller} style={{height: initiated === true ? 200 : 300, overflowX : "scroll",  animation: 'scroll 10s linear infinite'}} className="hscroll d-flex align-items-center justify-content-start             ">
          <img style={{ position: 'sticky', left : '3%', height: 40, borderRadius: 15, backgroundColor: 'white', cursor:'pointer', zIndex: 60}} onClick={()=>left()} src={left_icon} alt={"left icon"} className="px-1 mb-5"></img>
          
          {
          // console.log("return-grups",groups) && // && groups && groups.names && groups.names.length > 0 ? 
          groups && groups.names  && groups.names.map((name, index)=>(
            
            <div key={index} style={{height: initiated === true ? 180 : 250, position : "relative", right: 160}} className="col-lg-4 mr-4 px-0 d-flex align-items-center justify-content-start">
              <div onClick={()=>sendMessage(`Show ${name} clinical trials`)} style={{textWrap:'wrap', cursor:'pointer'}}>
                  <img style={{height: initiated === true ? 140 : 170,borderRadius:15}} src={groups.images[index]} alt={name}></img>
                  <h6 className="col-lg-10 my-2">{name}</h6>
              </div>
            </div>

          //  <div ref={(el) => (cardRefs.current[cardIndex] = el)} style={{transitionTimingFunction: "ease-in-out",  height: index === cardIndex ? 250 : 180}} key={index} className="d-flex align-items-center justify-content-center mx-3">
          //       <div onClick={()=>sendMessage(`Show ${name} clinical trials`)} style={{textWrap:'wrap', cursor:'pointer'}} className="text-center">
          //           <img style={{borderRadius:15}} className={`mx-0 normal_card ${index === cardIndex ? "active_card" : ""}`} src={groups.images[index]} alt={name}></img>
          //           <h6 className="my-2">{name}</h6>
          //       </div>
          //   </div>
          
           )
            )

          // :
          // cards && cards.length > 0 ?
          //  cards.map((c_item, i)=>
          //   <div ref={(el) => (cardRefs.current[cardIndex] = el)} style={{transitionTimingFunction: "ease-in-out",  height: i === cardIndex ? 250 : 180}} key={i} className="d-flex align-items-center justify-content-center mx-3">
          //       <div onClick={()=>sendMessage(`Show ${c_item.text} clinical trials`)} style={{textWrap:'wrap', cursor:'pointer'}} className="text-center">
          //           <img style={{borderRadius:15}} className={`mx-0 normal_card ${c_item && c_item.id === cardIndex ? "active_card" : ""}`} src={c_item.icon} alt="c_icon"></img>
          //           <h6 className="my-2">{c_item.text}</h6>
          //       </div>
          //   </div>
           // <div key={c_item.id} style={{height: initiated === true ? 180 : 150, 
            //   position :  "relative",//(c_item.id !== 1 ? "relative" : "static"),
            //   right : getCardRight(c_item.id),
            //   zIndex: selected_card && selected_card.id === c_item.id ? 50 : getZindex(c_item.id)
            //   }} className="col-lg-4 mr-4 px-0 d-flex align-items-center justify-content-start">
            //     {selected_card && selected_card.id === c_item.id ? 
            //     <div onClick={()=>sendMessage(c_item.text)} style={{textWrap:'wrap', cursor:'pointer',backgroundColor:'white', borderRadius: 15, zIndex: 100}}>
            //         <img className="w-100"  style={{height: 195,borderRadius:15}} src={selected_card.icon} alt="c_icon"></img>
            //         <h6 style={{height:80,backgroundColor:'white'}} className="col-lg-10 my-2">{selected_card.text}</h6>
            //     </div>
            //       : 
            //       <div onClick={()=>sendMessage(c_item.text)} style={{textWrap:'wrap', cursor:'pointer',backgroundColor:'white', borderRadius: 15}}>
            //         <img  className="w-100" style={{height: c_item.id === 2 ? 145 : c_item.id === 4 ? 145 : 135,borderRadius:15}} src={c_item.icon} alt="c_icon"></img>
            //         <h6 style={{height:80,backgroundColor:'white'}} className="col-lg-10 my-2">{c_item.text}</h6>
            //     </div>
            //     }
            // </div>
          // ) : null
    
        }
          <img style={{ position: 'sticky', right : '3%', height: 40, borderRadius: 15, backgroundColor: 'white', cursor:'pointer', zIndex: 60}} onClick={()=>right()} src={right_icon} alt={"right icon"} className="px-1 mb-5"></img>

      </div>
    )
  }

// if(uid && uid.uid && uid.uid.length > 0){
//   socket.onopen = (event) => {
//     console.log('WebSocket connection established.', event);
//   };
// }


// useEffect (() => {
//   socket.onmessage = (event) => {
//     const message = event.data; 
//     console.log('Received message:', message);


//     if(all_messages && all_messages.length > 0){
//       let all_chat = [... new Set(all_messages.concat([JSON.parse(message)]))]
//       console.log(all_chat,'all chat')
//       set_All_Messages(all_chat)
//       set_Messages(all_chat)
//       // setTimeout(() => {
//       //   set_Messages(all_messages)
//       // }, 2000);
//     }else{
//       console.log(JSON.parse(message))
//       set_All_Messages([JSON.parse(message)])
//       // setTimeout(() => {
//       //   set_Messages(all_messages)
//       // }, 2000);
//       set_Messages(all_messages)
//     }
       
//   };  
// },[messages])

socket.onclose = async (event) =>{
  // setTimeout(()=>{
  //   socket.send(JSON.stringify({type: "not_message"}))
  //   //socket.onopen((event)=>{console.log(event,'socket reopened while closing')})
  // },600)
}

const moveDown = () => {
    if (chatRef.current) {
      console.log(chatRef.current.scrollHeight,'sh')
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };


useEffect(()=>{
  fetchChatActivity(uid.uid).then(gotActivity=>{
    console.log(gotActivity,'got user activity')
    if(gotActivity && gotActivity.length > 0){
      let filter_non_empty = gotActivity.filter(item => item.chat_activity && item.chat_activity.length > 0)
      if(filter_non_empty && filter_non_empty.length > 0){
        set_prev_activity(filter_non_empty)
      }
    }else{
      set_prev_activity([])
    }
  }).catch(errActivity=>{
    console.log(errActivity,'err user activity')
    set_prev_activity([])
  })
},[])

// useEffect (() => {
//   socket.onmessage = (event) => {
//     const message = event.data; 
//     //console.log('Received message:', message);
//     let parsed_message = JSON.parse(message)
//     if(parsed_message && parsed_message.type == "not_message"){
//       console.log('not actual message')
//     }
//     console.log('Received message:',parsed_message && parsed_message.text);
//     let activity_role = parsed_message && parsed_message.from &&  parsed_message.from.role;
//     console.log('role:', activity_role);
//     if(activity_role !== undefined){
//     set_total_chat((prevMessages) => [...prevMessages,...[{ text :  parsed_message.text, role: "bot", id :  parsed_message.id, received_at : get_date_time()}]]);
//     set_receiving(false)

//     console.log('parsed message', parsed_message)
//     if(parsed_message && parsed_message["from"] && parsed_message["from"]["role"] && parsed_message["from"]["role"] == "bot"){
//       console.log(parsed_message.text, 'bot message',selected_chat && selected_chat.chat_activity, total_chat, selected_chat && selected_chat.chat_activity && selected_chat.chat_activity.length, total_chat.length)
//       if(total_chat && total_chat.length > 0){
//         if(selected_chat && selected_chat.chat_activity && selected_chat.chat_activity.length < total_chat.length){
//           let bot_message = [{text : parsed_message.text, role : "bot", id : parsed_message.id, received_at : get_date_time()}]
//           // set_total_chat([...total_chat, ...bot_message])
//           updateChatActivity(selected_chat._id, { chat_activity : [...total_chat, ...bot_message]}).then(uc=>{
//             console.log(uc, 'updated activity')
//             set_selected_chat(uc)
//           }).catch(errUc=>{
//             console.log(errUc,'err updating chat activity')
//           })
//         }else if(selected_chat === ""){
//           let bot_message = [{text : parsed_message.text, role : "bot", id : parsed_message.id, received_at : get_date_time()}]
//           if(prev_activity && prev_activity.length > 0){
//             let check_for_today = prev_activity.filter(item => new Date(item.createdAt) == new Date(Date.now()))
//             console.log(check_for_today,' today activity')
//             if(check_for_today && check_for_today.length > 0){

//             }else{
//                 console.log(selected_chat,'selected chat')
//                 let user_messages = total_chat.filter(item=> item.role === "user")
//                 console.log(user_messages && user_messages.length,user_messages[user_messages.length - 1], 'last item')
//                 let input = {
//                   chat_activity : [...total_chat, ...bot_message],
//                   initial_message : user_messages[user_messages.length - 1]
//                 }
//                 console.log(input,'chat input')
//                saveChatActivity(input).then(s=>{
//                 console.log(s,'activity saved')
//                 set_selected_chat(s)
//                }).catch(errChat=>{
//                 console.log(errChat,'err while saving chat')
//                })
//                //e.preventDefault();
//             }
//           } else{
//             let bot_message = [{text : parsed_message.text, role : "bot", id : parsed_message.id, received_at : get_date_time()}]
//             if(total_chat && total_chat.length > 0){
//                   let user_messages = total_chat.filter(item=> item.role === "user")
//                   let input = {
//                     chat_activity : [...total_chat, ...bot_message],
//                     initial_message : user_messages[user_messages.length - 1]
//                   }
//                   console.log(input,'chat input')
//                  saveChatActivity(input).then(s=>{
//                   console.log(s,'activity saved')
//                   set_selected_chat(s)
//                  }).catch(errChat=>{
//                   console.log(errChat,'err while saving chat')
//                  })
//                 }
//           }
//         }
//       }else{
//         console.log(parsed_message.text, 'bot message', total_chat)
//         // let bot_message = [{ text : parsed_message.text, role : "bot", id : parsed_message.id }]
//         // set_total_chat(bot_message)
//     }
//     }
//     }
//   };  
//   // window.addEventListener('beforeunload', async function (e) {
//   //   if(total_chat && total_chat.length > 0){
//   //     let user_messages = total_chat.filter(item=> item.role === "user")
//   //     let input = {
//   //       chat_activity : total_chat,
//   //       initial_message : user_messages[user_messages.length - 1]
//   //     }
//   //     console.log(input,'chat input')
//   //    saveChatActivity(input).then(s=>{
//   //     console.log(s,'activity saved')
//   //    }).catch(errChat=>{
//   //     console.log(errChat,'err while saving chat')
//   //    })
//   //    e.preventDefault();
//   //   }
//   // });
// },[input_message])

useEffect(()=>{
  socket.onopen = () => {
      console.log('WebSocket connection established');
  };

  socket.onmessage = (event) => {
      const message = event.data; 
      console.log('Received message:', message);
      let parsed_message = JSON.parse(message)
      if(parsed_message && parsed_message.type == "not_message"){
        console.log('not actual message')
        return
      }
      set_total_chat((prevMessages) => [...prevMessages,...[{ text :  parsed_message.text, role: "bot", id :  parsed_message.id, received_at : get_date_time()}]]);
      set_receiving(false)
  };

  socket.onclose = () => {
      console.log('WebSocket connection closed');
  };

  socket.onerror = (error) => {
      console.error('WebSocket error', error);
  };

  // Clean up the socket connection when the component unmounts
  return () => {
      socket.close();
  };
},[])

useEffect(()=>{
  moveDown()
},[total_chat])

useEffect(()=>{
  console.log(socket.readyState, socket.OPEN, 'socket states')
  setInterval(()=>{
    socket.send(JSON.stringify({type:"not_message"}))
  },45*1000)
  if(socket.readyState != socket.OPEN){
    socket.onopen = (event)=>{
      console.log(event,'reopening socket');
      socket.send(JSON.stringify({type: "not_message"}))
    }
  }else if(socket.readyState == 0){
    console.log(socket.readyState,'socket connecting')
  }else if(socket.readyState == socket.OPEN){
    console.log(socket.readyState,'socket opening')

  }
  else{
    console.log(socket.readyState,'socket unknown')
  }
},[socket.readyState])


const logout = () =>{
    //  if(total_chat && total_chat.length > 0){
    //   let user_messages = total_chat.filter(item=> item.role === "user")
    //   let input = {
    //     chat_activity : total_chat,
    //     initial_message : user_messages[user_messages.length - 1]
    //   }
    //   console.log(input,'chat input')
    //  saveChatActivity(input).then(s=>{
    //   console.log(s,'activity saved')
    //   userLogout().then(success=>{
    //     console.log(success,'user logged out')
    //   }).catch(failure=>{
    //     console.log(failure,'err with logout')
    //   })
    //  }).catch(errChat=>{
    //   console.log(errChat,'err while saving chat')
    //  })
    //  //e.preventDefault();
    // }else{
      userLogout().then(success=>{
        console.log(success,'user logged out')
      }).catch(failure=>{
        console.log(failure,'err with logout')
      })
    //}
}

const addBreaks = (text) => {
    // Replace double newlines with two <br> tags
    let sentences = text.split(/\n\n/g);
    console.log(sentences,'breaking sentences')
    if(sentences && sentences.length > 0){
      let refers = sentences.map(item => { 
        return item.replace(/\[\d+\]/ , '') 
      })
      console.log(refers,'removing refers')
      return refers
    } else{
      return []
    }
    // // Replace single newlines with one <br> tag
    // text = text.replace(/\n/g, '<br>');
    // return text;
  }

const displayBotResponse = (message) =>{
    let message_without_citations = message && message.split(/\[\d+\]: cite:/) && addBreaks(message.split(/\[\d+\]: cite:/)[0])
    let trial_message = message_without_citations && message_without_citations.toString() && message_without_citations.toString().split(/clinical trials:/g)
    let message_with_references = message_without_citations && message_without_citations.length > 0 ?  message_without_citations.toString().split(/\[\d+\]/) : []
    //let breaking_sentences = message_without_citations && message_without_citations.split(/[a-zA-Z0-9\s]+.!?/);
    // let get_citations = message.split(/\[\d+\]: cite:/)
    // console.log(get_citations,'citations')
    //console.log(breaking_sentences,'breaking sentencces')
    console.log(trial_message,message_with_references, message_without_citations, 'references')
    let next_queries = message_without_citations && message_without_citations.length > 0 ?  message_without_citations.toString().split("Next user query recommendations:") : ""
    console.log(next_queries && next_queries[1],'queries')
    let sep_next_queries = next_queries && next_queries[1] && next_queries[1].split(/\d+\./g)
    console.log(sep_next_queries,'next queries')
    if(message && message.length > 0){
      console.log(message, message_with_references)
      if((message_without_citations && message_without_citations.length == 0) &&  (message_with_references && message_with_references.length > 0)){
       return (
        <div key={message.id}>
            {message_with_references.map(item=>
            // <p className="mb-1">{item}</p>
            {
                  item && item.split(/clinical trials:/g) && item.split(/clinical trials:/g).length > 0 ? 
                  <div>
                  <ReactMarkdown>{item}</ReactMarkdown>
                  { /*<button className="btn btn-sm btn-primary" href="http://34.221.168.128/enroll/STU0001002">Connect me to site</button> */}
                  </div> 
                  : 
                  <ReactMarkdown>{item}</ReactMarkdown>
            }
             )}
             {sep_next_queries && sep_next_queries.length > 0 ? 
             <div>
                <h6>Next Input Recommendations:</h6>
                {sep_next_queries.map(next_query=>
                  <div style={{border:'1px solid black', borderRadius: 15,}} className="p-1 m-1">
                    <p onClick={()=>{
                      set_Input_Message(next_query)
                      sendMessage(next_query)
                    }
                    // this.setState({ input_message : next_query},()=>{
                    //   sendMessage(next_query)
                    // })
                    } style={{textDecoration:'underline', textDecorationColor:'blue', cursor:'pointer'}} className="mb-0">{next_query}</p>
                  </div>
                )}
             </div> : null}
             {message && message.entities && message.entities.length > 0 ? 
              <div>
                  <p className="mb-0">Citations: </p>
                  {message.entities.map(citation=> 
                  (JSON.parse(citation.text))+
                  <div>
                    <h6>{JSON.parse(citation.text).id_info.nct_id}</h6>
                    
                  </div>
                  )}
              </div>
             : null}
        </div>
       )

        // {next_queries[1].split("1.").map(item=>
        //     <div style={{border: '1px solid black'}}>
        //         <p className="mb-1">{item}</p>
        //     </div>
        // )} 
      }
      else{
        console.log(message_without_citations, 'text w/o citations')
        return (
        <div>
            {message_without_citations && message_without_citations.length > 0 ?
             message_without_citations.map(line =>
          <div>
            {line && line.length > 0 ? 
              // <p>{line.replace('.,','') || line.replace('.','') }</p> 
              
                trial_message && trial_message.length > 0 ? 
                <div>
                <ReactMarkdown>{line}</ReactMarkdown>
                {/* <button className="btn btn-sm btn-primary" onClick={()=> window.open("http://34.221.168.128/enroll/STU0001002","_blank")}>Connect me to site</button> */}
                </div> 
                : 
                <ReactMarkdown>{line}</ReactMarkdown>
          
              : ""
            }
            <br></br>
          </div>
        )
        : ""
        }
        </div>
        )
      }
    }else{
      return ""
    }
  }

  // render() {

    //let { messages, input_message } = this.state
    return (
      <div>
        <Header loggedin={true} logout={logout} />
        <div className="px-2 mx-2 row">
              <div style={{height: 600}} className="col-lg-2">
                  <h6>Activity</h6>
                  <div style={{height: 600, overflowY : 'scroll'}}>
                  {
                    prev_activity && prev_activity.length > 0 ? 
                    prev_activity
                    // .filter(
                    //   (item) =>
                    //   new Date(item.updatedAt).getDate() == new Date(Date.now()).getDate()
                    // )
                    .sort(
                      (d1, d2) =>
                        new Date(d1.updatedAt).getTime() -
                        new Date(d2.updatedAt).getTime()
                    )
                    .reverse().map(p_item=>
                      <div 
                        onClick={()=>{
                          set_total_chat([])
                          set_selected_chat(p_item)
                          set_total_chat(p_item.chat_activity)
                        }} style={{border: "1px solid dodgerblue", borderRadius:10, backgroundColor: "#ECF9FF", cursor: 'pointer'}} className="my-2 p-2 mx-0">
                        <h6 style={{textDecoration : "none", textTransform: 'capitalize'}} className="mb-0">{p_item.initial_message}</h6>
                        <p style={{fontSize: 13}} className="mb-0 py-1">{get_specific_date_time(p_item.updatedAt)}</p>
                      </div>
                      )
                    :
                    <p>No Activity Found</p>
                  }
                  </div>
                  {/* <h6>Previous Activity</h6>
                  <div style={{height: 280, overflowY : 'scroll'}}>
                  {
                    prev_activity && prev_activity.length > 0 ? 
                    prev_activity
                    .filter(
                      (item) =>
                      new Date(item.updatedAt).getDate() != new Date(Date.now()).getDate()
                    )
                    .sort(
                      (d1, d2) =>
                        new Date(d1.updatedAt).getTime() -
                        new Date(d2.updatedAt).getTime()
                    )
                    .reverse().map(p_item=>
                      <div 
                      onClick={()=>{
                        set_total_chat([])
                        set_selected_chat(p_item)
                        set_total_chat(p_item.chat_activity)
                      }} style={{border: "1px solid dodgerblue", borderRadius:10, backgroundColor: "#ECF9FF", cursor: 'pointer'}} className="my-2 p-2 mx-0">
                      <h6 style={{textDecoration : "none", textTransform: 'capitalize'}} className="mb-0">{p_item.initial_message}</h6>
                      <p style={{fontSize: 13}} className="mb-0 py-1">{get_specific_date_time(p_item.updatedAt)}</p>
                    </div>
                    )
                    :
                    <p>No Activity Found</p>
                  }
                  </div> */}
              </div>
              <div className="col-lg-7">
                  <div style={{height: 625, backgroundColor: '#F1F1F1'}}>
                        {total_chat && total_chat.length > 0 ? 
                          <div style={{backgroundColor:'white'}}>
                            {/* <div className="d-flex align-items-center justify-content-center">
                                <img style={{height:40}} src={bot} alt="bot"></img>
                                <h6 className="my-3 mx-3">Trials.me Copilot for Clinical Trials</h6>
                            </div> */}
                            <div className="">
                              <ChatItems initiated={true} />
                            </div>
                          </div>
                            :
                            <div></div>
                        }
                        <div style={{}}>
                            <div id="chat" ref={chatRef} style={{ height: 375, overflowY:"scroll"}}>
                              {
                                  total_chat && total_chat.length > 0 ? 
                                  total_chat.map(message=>
                                      message && message.role && message.role === "bot" ?
                                  <div className="d-flex align-items-start justify-content-start my-2">
                                      <div style={{backgroundColor:'#ECF9FF', borderRadius: 10, minWidth: 400}} className="p-3 mx-2">
                                          <p className="mb-1">{message && message.role && message.role === "bot" && "Auto Agent" || "Guest"}</p>
                                          {displayBotResponse(message.text)}
                                          {message && message.received_at && message.received_at.length > 0 ? 
                                            <div className="d-flex align-items-center justify-content-end">
                                                <p style={{color:'black', fontSize : 10, fontWeight : 400, fontFamily:"LatoRegular"}} className="mb-1">{message.received_at}</p>
                                            </div> 
                                          : null}
                                      </div>
                                  </div> : 
                                  <div className="d-flex align-items-end justify-content-end my-2">
                                      <div style={{backgroundColor:'#D2E0FB', borderRadius: 10, minWidth: 400}} className="p-3 mx-2">
                                          <p style={{color:'black'}} className="mb-1">{message && message.role && message.role === "bot" && "Auto Agent" || "Guest"}</p>
                                          <h6 style={{color:'black'}}>{message.text}</h6>
                                          {message && message.received_at && message.received_at.length > 0 ? 
                                            <div className="d-flex align-items-center justify-content-end">
                                                <p style={{color:'darkslategray', fontSize : 10, fontWeight : 400, fontFamily:"LatoRegular"}} className="mb-1">{message.received_at}</p> 
                                            </div>
                                          : null}
                                      </div>
                                  </div>
                                  )
                                  :
                                  <div className="">
                                    <ChatItems initiated = {false} />
                                  </div>

                              }
                          </div>
                      </div>
                  </div>
                  <div className="py-1">
                      {receiving === true ? 
                          <div>
                              <img style={{height:40}} src={receiving_icon} alt="loading"></img>
                          </div>
                       : null}
                      <div style={{height:70,border:'1.5px solid black', borderRadius: 45}} className="d-flex align-items-center justify-content-center px-1 pl-4">
                          <input style={{height : 60, border:"none",outline:'none', fontSize: 20, fontWeight:"normal"}} className="w-100 p-3" name="input_message" value={input_message} onKeyDown={handleKeyDown} placeholder="Type your message here . ." onChange={handleTextChange}></input>
                          <img onClick={()=>sendMessage(input_message)} style={{height:50,width:50, cursor: 'pointer'}} src={send_message_icon} alt="send"></img>
                      </div>
                  </div>
              </div>
              <div className="col-lg-3 px-0 mx-0">
                  <p className="mb-1">Sponsored Studies</p>
                  <div style={{height: 600, overflowY : "scroll"}}>
                    {prod_studies.map((study,i)=>
                        <PTCard
                        key={(study && study.study_sys_id) || i}
                        nctNumber={study && study.nct_number}
                        snow_study_number={study && study.snow_study_number}
                        phase={
                          study &&
                          study.study_phase &&
                          study.study_phase.length > 0
                            ? study.study_phase
                            : "None"
                        }
                        condition={
                          study &&
                          study.conditions &&
                          study.conditions.length > 0
                            ? study.conditions
                            : "None"
                        }
                        locationCount={1}
                        study_sponsor_url={study && study.study_sponsor_url}
                        locationZipcode={study && study.pin_code}
                        title={
                          `${study && study.official_title}`.length > 0
                            ? `${study && study.official_title}`.length > 60
                              ? `${study && study.official_title}`.slice(
                                  0,
                                  100
                                ) + "..."
                              : `${study && study.official_title}`
                            : study && study.brief_title
                        }
                        status={study && study.status}
                        sex={study && study.sex}
                        maximum_age={study && study.maximum_age}
                        minimum_age={study && study.minimum_age}
                        site_name={study && study.site_name}
                        study_name={study && study.study_name}
                        description={
                          `${study && study.brief_summary}`.length > 0
                            ? `${study && study.brief_summary}`.length > 60
                              ? `${study && study.brief_summary}`.slice(
                                  0,
                                  100
                                ) + "..."
                              : `${study && study.brief_summary}`
                            : "None"
                        }
                        // trialSponsor={"Grady Memorial Hospital"}
                        // trialsGoal={"Diagnostic, Treatment"}
                        //sysId={ptstudy && ptstudy.sys_id}
                        sysId={study && study.snow_study_number}
                        staticLink={study && study.static_recruitment_link}
                        vendor={study && study.vendor}
                        embeddedLink={study && study.embedded_link}
                      /> 
                    )}
                  </div>
              </div>
        </div>
      </div>
    );
  // }
}

export default HomeController;
