import axios from "axios";


const inputMessage = (message) =>{
    return new Promise((resolve, reject)=>{
        axios.post("/message",{
            message : message
        },{
            headers:{
                "Content-Type": "application/json"
            }
        }).then(success=>{
        console.log(success.data,'success')
        resolve(success.data.output)
        }).catch(failure=>{
            console.log(failure,'failed to  send message')
            reject([])
        })
    })
}
// const fetchChat = (watermark) =>{
//     return new Promise((resolve, reject)=>{
//         axios.get(`/chat?wm=${watermark || "-"}`).then(gotChat=>{
//             console.log(gotChat,'gotChat')
//             resolve(gotChat.data.output)
//         }).catch(errChat=>{
//             console.log(errChat,'failed to receive messages')
//             reject([])
//         })
//     })
// }
const fetchChatActivity = (id) =>{
    return new Promise((resolve, reject) =>{
        axios.get(`/chat_activity?id=${id || ""}`).then(gotChat=>{
                        //console.log(gotChat,'gotChat')
                        resolve(gotChat.data.output)
                    }).catch(errChat=>{
                        console.log(errChat,'failed to receive messages')
                        reject([])
                    })
    })
}

const fetchConditionGroups = (condition) =>{
    return new Promise((resolve, reject) =>{
        axios.get(`/qgroups?condition=${condition || ""}`).then(gotGroups=>{
                       //console.log(gotGroups,'got groups')
                        resolve(gotGroups.data.output)
                    }).catch(errGroups=>{
                        console.log(errGroups,'failed to get groups')
                        reject([])
                    })
    })
}

const saveChatActivity = (body) =>{
    return new Promise((resolve, reject)=>{
        axios.post("/c_activity",body,{
            headers:{
                "Content-Type": "application/json"
            }
        }).then(success=>{
        console.log(success.data,'success with saving activity')
        resolve(success.data.output)
        }).catch(failure=>{
            console.log(failure,'failed to save activity')
            reject([])
        })
    })
}

const updateChatActivity = (id, body) =>{
    return new Promise((resolve, reject)=>{
        axios.put(`/c_activity?id=${id}`,body,{
            headers:{
                "Content-Type": "application/json"
            }
        }).then(successChatUpdate=>{
        console.log(successChatUpdate.data,'success with updating chat activity')
        resolve(successChatUpdate.data.output)
        }).catch(failureChatUpdate=>{
            console.log(failureChatUpdate,'failed to update chat activity')
            reject([])
        })
    })
}

const userLogout = () =>{
    return new Promise((resolve, reject)=>{
        axios.post("/logout",{
            headers:{
                "Content-Type": "application/json"
            }
        }).then(success=>{
        console.log(success.data,'user logged out')
        window.location.href = "/"
        }).catch(failure=>{
            console.log(failure,'error with user logout')
            reject(null)
        })
    })
}

const fetchNlp = (message) =>{
    return new Promise((resolve, reject) =>{
        axios.get(`/nlp?q=${message || ""}`).then(gotNlp=>{
                        console.log(gotNlp,'got output')
                        resolve(gotNlp.data.output)
                    }).catch(errNlp=>{
                        console.log(errNlp,'failed to output')
                        reject([])
                    })
    })
}

export {
    inputMessage,
    //fetchChat,
    fetchConditionGroups,
    updateChatActivity,
    fetchChatActivity,
    saveChatActivity,
    userLogout,
    fetchNlp,
}