import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import "../bootstrap.css";
import {logo,bot  } from "../theme/Icons";


const Login = (props) => {
  const { text } = props;


  return (
      <div className="container-fluid px-5 mx-5">
           <div className="d-flex align-items-center justify-content-center">
                <div className="text-center my-3">
                    <img
                        className="navbar-brand logo my-2"
                        alt="logo"
                        src={logo}
                        style={{cursor:'pointer'}}
                        onClick={()=> window.location.href = "/"}
                    ></img>
                    <br></br>
                    <img className="my-3" style={{height:100}} src={bot} alt="bot"></img>
                    <br></br>
                    <h3 className="my-2">Etrials.io Auto Agent</h3>
                    <br></br>
                    <h6 className="my-2">Your Smart Companion for searching clinical trials easier and faster</h6>
                    <br></br>
                    <button onClick={()=> 
                        window.location.href = "/auth/google"
                        } className="btn btn-primary btn-lg">Sign in with Google</button>
                </div>
           </div>
      </div>

  );
};

export default Login;
