import React from "react";
import "../App.css";
import "../bootstrap.css";

import {
  greenDot,
  viewMore,
  logo,
  antidoteFaviLogo,
  trialscopeFaviLogo,
} from "../theme/Icons";
const environment = "DEV"


const promoStudies = [
  { nctid: "NCT01716806", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04232280", logo: trialscopeFaviLogo, text: "trialscope" },
  {
    nctid: "NCT03095066",
    logo: trialscopeFaviLogo,
    text: "trialscope",
    width: 110,
  },
  { nctid: "NCT03043313", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT03896945", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04528719", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04860297", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04538989", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT03288545", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04144348", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04813796", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04956575", logo: trialscopeFaviLogo, text: "trialscope" },
  { nctid: "NCT04628481", logo: trialscopeFaviLogo, text: "PCRI" },
  { nctid: "NCT04695977", logo: trialscopeFaviLogo, text: "trialscope" },
  {
    nctid: "NCT04053634",
    logo: antidoteFaviLogo,
    text: "antidote.me",
    width: 120,
  },
  {
    nctid: "NCT04133909",
    logo: antidoteFaviLogo,
    text: "antidote.me",
    width: 120,
  },
];

const getPartnerLogo = (nctid, vendor, study_sponsor_url) => {
  if (
    (vendor && vendor.length) === 0 ||
    vendor === "" ||
    vendor === " " ||
    vendor === "undefined" ||
    vendor === null
  ) {
    let study = promoStudies.filter((promostudy) => promostudy.nctid === nctid);
    //console.log(study, 'study logo')
    if (study && study.length > 0) {
      return study[0].logo;
    } else {
      if (study_sponsor_url && study_sponsor_url.length > 0) {
        return study_sponsor_url;
      } else {
        return logo;
      }
    }
  } else if (
    vendor === "trialscope" ||
    vendor === "Trialscope" ||
    vendor === "TRIALSCOPE"
  ) {
    return trialscopeFaviLogo;
  } else if (
    vendor === "antidote" ||
    vendor === "Antidote" ||
    vendor === "ANTIDOTE"
  ) {
    return antidoteFaviLogo;
  } else {
    let study = promoStudies.filter((promostudy) => promostudy.nctid === nctid);
    if (study && study.length > 0) {
      return study[0].logo;
    } else {
      return logo;
    }
  }
};
const getPartnerText = (nctid) => {
  let study = promoStudies.filter((promostudy) => promostudy.nctid === nctid);
  if (study && study.length > 0) {
    return study[0].text;
  } else {
    return "trials.me";
  }
};
const getWidth = (nctid, vendor, study_sponsor_url) => {
  if (nctid === "NCT04053634" || nctid === "NCT04133909") {
    return "ptrlogo favlogowidth";
  } else if (study_sponsor_url && study_sponsor_url.length > 0) {
    let study = promoStudies.filter((promostudy) => promostudy.nctid === nctid);
    if (study && study.length > 0) {
      return "ptrlogo";
    } else if (vendor && vendor.length > 0) {
      return "ptrlogo";
    } else {
      return "sponsorlogo";
    }
  } else {
    return "ptrlogo";
  }
};

const PromoClick = (link) => {
  console.log(`${link}, "link"`);
  window.open(link, "_self");
};

const PTCard = (props) => {
  const {
    condition,
    snow_study_number,
    nctNumber,
    vendor,
    staticLink,
    title,
    description,
    study_sponsor_url,
    sysId,
    site_name,
    study_name,
    status,
    sex,
    maximum_age,
    minimum_age,
  } = props;

  const   onPTCardClick = (sysId) => {
    //this.props.history.push(`/study/${sysId}`);
    window.open( `https://trials.me/study/${sysId}`, "_blank")
  };

  const onCard = () => {
    console.log(staticLink, "static link");
    // window.sessionStorage.setItem("snow_study_number", snow_study_number);
    // if (environment === "PROD" && snow_study_number === "STU01009") {
    //   // PromoClick("/upcoming-research");
    //   onPTCardClick(nctNumber);
    // } else if (environment === "DEV" && snow_study_number === "STU0001002") {
    //   // PromoClick("/upcoming-research");
    //   onPTCardClick(nctNumber);
    // } else if (staticLink && staticLink.length > 0) {
    //   window.location.href = staticLink
    //   // PromoClick(staticLink);
    // } else {
    //   if (nctNumber && nctNumber.length > 0) {
    //     onPTCardClick(nctNumber);
    //   } else {
    //     onPTCardClick(snow_study_number);
    //   }
    // }
    window.open( `https://trials.me/study/${nctNumber}`, "_blank")
  };

  const getTrialAge = (min, max) => {
    if (min && min.length === 0 && max && max.length === 0) {
      return "None";
    } else if (min === "" && max === "") {
      return "None";
    } else if (min === " " && max === " ") {
      return "None";
    } else if (min === undefined && max === undefined) {
      return "None";
    } else if (min === null && max === null) {
      return "None";
    } else if (
      min === " " ||
      min === "" ||
      min === undefined ||
      min === null ||
      (min && min.length) === 0
    ) {
      return `below ${maximum_age} years`;
    } else if (
      max === " " ||
      max === "" ||
      max === undefined ||
      max === null ||
      (max && max.length) === 0
    ) {
      return `above ${minimum_age} years`;
    } else {
      return `${minimum_age} - ${maximum_age} years`;
    }
  };
  return (
    <div
      onClick={() => onCard()}
      style={{
        filter: "drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15))",
        cursor: "pointer",
      }}
      className="card border rounded pb-3 pl-3 mx-0 my-3"
    >
      <div className="d-flex align-items-start justify-content-between">
        <div className="pt-4 d-flex align-items-center justify-content-center">
          {/* <img className="mx-2" src={greenDot} alt="greendot"></img> */}
          {/* <span className="mx-1">{phase}</span> */}
          {/* <span className="mx-1">{"|"}</span> */}
          <span className="mb-0">
            {study_name && study_name.length > 60
              ? study_name.slice(0, 60) + "..."
              : study_name}
          </span>
          {/* {condition && condition.length > 0 ? (
            condition && condition.length > 40 ? (
              <span>{condition.slice(0, 40) + "..."}</span>
            ) : (
              <span>{condition}</span>
            )
          ) : (
            <span className="mx-1">{"None"}</span>
          )} */}
        </div>
        <div>
          <div className="m-0 p-0 text-right ptr_logo">
            {/* <span className="pt_advertise_span mx-1">advertised</span> */}
            {/* <img
              className="pt_advertise_img"
              style={{height:20,width:20}}
              src={"https://logodix.com/logo/45540.jpg"}
              alt="yellow star"
            ></img> */}
          </div>
          <div className="ml-4">
            <img
                style={{height:30}}
              src={getPartnerLogo(nctNumber, vendor, study_sponsor_url)}
              alt="PTfavicon"
              className={getWidth(nctNumber, vendor, study_sponsor_url)}
            ></img>
            <div className="partnertext">{getPartnerText(nctNumber)}</div>
            {/* <img src={bookmark_inactive} alt="select tag"></img> */}
          </div>
        </div>
      </div>
      <div className="row card_description">
        <div className="col-sm-12 col-md-8 col-lg-8">
          <h6 className="my-3">{title}</h6>
          <p className="my-3">{description}</p>
        </div>
      </div>
      {/* <div className="ptcard_bottom row d-flex align-items-center justify-content-start">
        <div className="mb-0 col-lg-3 col-xs-12 d-flex align-items-center justify-content-start">
          <p className="mb-0">Gender: </p>
          <span className="mb-1 mx-2">
            {sex && sex.length > 0 ? sex : "None"}
          </span>
        </div>
        <div className="mb-0 col-lg-5 col-xs-12 d-flex align-items-center justify-content-start">
          <p className="mb-0">Conditions: </p>
          <span className="mb-1 mx-2">
            {condition && condition.length > 0
              ? condition && condition.length > 20
                ? condition.slice(0, 20) + "..."
                : condition
              : "None"}
          </span>
        </div>
        <div className="mb-0 col-lg-4 col-xs-12 d-flex align-items-center justify-content-start">
          <p className="mb-0">Age: </p>
          <span className="mb-1 mx-2">
            {getTrialAge(minimum_age, maximum_age)}
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default PTCard;
