import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter, Redirect } from "react-router-dom";
import HomeController from "./pages/HomeController";
import Header from "./components/Header";
import NotFound from "./components/NotFound";
import Login from "./components/Login";


class Navigation extends React.Component {

  constructor(props) {
    super();
    this.state = {
      
    };
  }

  componentDidMount() {
    
  }

  componentWillUnmount() {
   
  }



  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/not_found" component={NotFound} />
          <Route exact path="/home/:uid/" component={HomeController} />
          {/* <Route path="*" component={NotFound} /> */}
        </Switch>
        <div style={{"box-shadow": "0px 1px 3px black", position : 'fixed', bottom : 0, left :0, right: 0 , zIndex : 200, backgroundColor : "white" }} className="container-fluid py-2">
            <div style={{display : "flex", alignItems : 'center', justifyContent: 'space-between'}} className = "footer">
                <h6 className="mb-0">Copyright © 2022 PatienTrials, a company of PatienTrials Inc.</h6>
                <div className="d-flex align-items-center justify-content-between">
                    <h6 className="mb-0 mx-3">Powered By PatienTrials</h6>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Navigation);
