import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import "../bootstrap.css";
import {logo,list  } from "../theme/Icons";


const menuItems = [
  { item: "Find trials", url: "/" },
  { item: "Sponsors", url: "https://patientrials.com/" },
  { item: "About", url: "https://patientrials.com/" },
  // { item: "Blog", url: "/blog" },
];

let message = "how are [1] you [1] doing today?"

let final = message.split("[1]").map(item=>
<div>
  <h6>{item}</h6>
</div>
)
console.log(final,'final')

const NotFound = (props) => {
  const { text } = props;


  return (
      <div className="container-fluid px-5 mx-5">
            <h6>Not Found</h6>
            {final}
      </div>

  );
};

export default NotFound;
